<template>
  <div class="lucky-draw-wraper">
    <section class="lucky-draw">
      <article class="banner">
        <footer class="winner-wraper">
          <noticePrize class="winner" :color="{li:'#FFFFFF',i:'#FDDB2C'}" ref="noticePcize"></noticePrize>
        </footer>
      </article>
      <div class="content-wraper">
        <!-- <div class="content"> -->
        <!-- <div class="drawing-wraper"> -->
        <ul class="drawing">
          <li>
            <div style="position:relative">
              <div class="pic-img1"></div>
              <span :class="[activeIndex===0?'active':'']"></span>
            </div>
            <div style="position:relative">
              <div class="pic-img2"></div>
              <span :class="[activeIndex===1?'active':'']"></span>
            </div>
            <div style="position:relative">
              <div class="pic-img3"></div>
              <span :class="[activeIndex===2?'active':'']"></span>
            </div>
          </li>
          <li>
            <div style="position:relative">
              <div class="pic-img4"></div>
              <span :class="[activeIndex===7?'active':'']"></span>
            </div>
            <div style="position:relative">
              <div :class="[isDrawing?'pic-img5-drawing':'pic-img5-draw']" @click="showSlot">
                <span class="draw-count">剩余{{availablePrize}}次</span>
              </div>
            </div>
            <div style="position:relative">
              <div class="pic-img6"></div>
              <span :class="[activeIndex===3?'active':'']"></span>
            </div>
          </li>
          <li>
            <div style="position:relative">
              <div class="pic-img7"></div>
              <span :class="[activeIndex===6?'active':'']"></span>
            </div>
            <div style="position:relative">
              <div class="pic-img8"></div>
              <span :class="[activeIndex===5?'active':'']"></span>
            </div>
            <div style="position:relative">
              <div class="pic-img9"></div>
              <span :class="[activeIndex===4?'active':'']"></span>
            </div>
          </li>
        </ul>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </section>
    <footer class="footer-wraper">
      <div class="my-points" @click="gotoMyDraw">
        <span class="title">我的奖品</span>
      </div>
      <p class="remark" @click="getLotteryMore">如何获取更多抽奖次数？</p>
    </footer>
  </div>
</template>

<script>
import noticePrize from 'components/notice-prize/index'
import { drawing, drawRecordPage } from 'api/draw'
import { getMyPointsSummary } from 'api/my-points'
import { getSessionStorage } from 'utils/store'
export default {
  components: {
    noticePrize
  },
  data () {
    return {
      userId: '',
      activeIndex: -1,
      count: 8,
      timer: 0,
      speed: 200,
      times: 0,
      cycle: 50,
      prize: -1,
      click: false,
      prizeDialogVisible: false,
      prizeIndex: -1,
      prizeName: '',
      prizeType: '',
      availablePrize: '--',
      prizeLevel: '',
      totalCount: '',
      newTotalCount: '',
      prizes: [],
      winningPrize: 1,
      isDrawing: false,
      rotate: true
    }
  },
  watch: {
    prizeDialogVisible () {
      if (this.prizeDialogVisible) {
        this.prizeDialog()
        this.prizeDialogVisible = false
      }
    }
  },
  methods: {
    initPrizePages () {
      let params = {
        userId: this.userId
      }
      drawRecordPage(JSON.stringify(params)).then((res) => {
        let { result, desc, data } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        this.totalCount = data.totalCount
      })
    },
    gotoMyDraw () {
      this.$router.push({ path: '/dc/prize' })
    },
    initgetMyPointsSummary () {
      let params = {}
      getMyPointsSummary(JSON.stringify(params)).then((res) => {
        let { result, desc, data } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        this.availablePrize = data.availablePrize
      })
    },
    showSlot () {
      if (this.isDrawing) {
        return
      }
      this.isDrawing = true
      if (this.availablePrize === 0) {
        const toast = this.$createToast({
          txt: `暂无抽奖机会`,
          type: 'error',
          time: 2000
        })
        toast.show()
        this.isDrawing = false
        return
      }
      let params = {}
      drawing(JSON.stringify(params)).then((res) => {
        let { result, desc } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        this.drawPrize()
        this.startLottery()
      })
    },
    // 开始抽奖
    startLottery () {
      if (this.click) {
        return
      }
      this.speed = 200
      this.click = true
      this.startRoll()
    },
    // 开始转动
    startRoll () {
      if (!this.rotate) {
        return
      }

      this.times += 1
      this.oneRoll()
      // 已中奖
      if (this.times > this.cycle + 10 && this.prize === this.activeIndex) {
        this.winningPrize++
        this.$refs.noticePcize._data.winningPrize = this.winningPrize
        this.times = 0
        this.click = false
        setTimeout(() => {
          this.isDrawing = false
          this.prizeDialogVisible = true
          this.initgetMyPointsSummary()
        }, 500)

        return
      }

      if (this.times < this.cycle) {
        this.speed -= 10
      } else if (this.times === this.cycle) {
        // 中奖位置
        this.prize = this.prizeIndex
        if (this.prize === -1) {
          this.activeIndex = -1
          this.timer = 0
          this.speed = 200
          this.times = 0
          this.click = false
          setTimeout(this.startLottery, 10)
          return
        }
      } else if (this.times > this.cycle + 10 && ((this.prize === 0 && this.activeIndex === 7) || this.prize === this.activeIndex + 1)) {
        this.speed += 110
      } else {
        this.speed += 10
      }

      if (this.speed < 40) {
        this.speed = 40
      }
      this.timer = setTimeout(this.startRoll, this.speed)
    },
    // 每一次转动
    oneRoll () {
      let activeIndex = this.activeIndex
      const count = this.count
      activeIndex += 1
      if (activeIndex > count - 1) {
        activeIndex = 0
      }
      this.activeIndex = activeIndex
    },
    drawPrize () {
      let count = 0
      this.rotate = true
      const timer = setInterval(() => {
        let params = {
          userId: this.userId
        }
        drawRecordPage(JSON.stringify(params)).then((res) => {
          let { result, desc, data } = res.data
          if (result !== 0) {
            const toast = this.$createToast({
              txt: `${desc}`,
              type: 'error',
              time: 2000
            })
            toast.show()
            return
          }
          count++
          if (count > 2) {
            this.rotate = false
            const toast = this.$createToast({
              txt: `当前参与人数过多，请稍后至"我的奖品"页面查看抽奖结果！`,
              type: 'error',
              time: 2000
            })
            toast.show()
            count = 0
            this.activeIndex = -1
            this.timer = 0
            this.speed = 200
            this.times = 0
            this.click = false
            clearInterval(timer)
            this.isDrawing = false
            return
          }
          this.prizes = data.page
          this.newTotalCount = data.totalCount
          if (this.totalCount !== this.newTotalCount) {
            clearInterval(timer)
            this.prizeName = this.prizes[0].name
            this.prizeLevel = this.prizes[0].prizeLevel
            switch (this.prizeLevel) {
              case 1:
                this.prizeType = '一等奖'
                this.prizeIndex = 1
                break
              case 2:
                this.prizeType = '二等奖'
                this.prizeIndex = 7
                break
              case 3:
                this.prizeType = '三等奖'
                this.prizeIndex = 3
                break
              case 4:
                this.prizeType = '四等奖'
                this.prizeIndex = 5
                break
              case 5:
                // this.prizeType = '五等奖'
                this.prizeType = '幸运奖'
                this.prizeIndex = 6
                break
              case 6:
                // this.prizeType = '六等奖'
                this.prizeType = '幸运奖'
                this.prizeIndex = 2
                break
              case 7:
                // this.prizeType = '七等奖'
                this.prizeType = '幸运奖'
                this.prizeIndex = 4
                break
              case 8:
                // this.prizeType = '八等奖'
                this.prizeType = '幸运奖'
                this.prizeIndex = 0
                break
              default:
                this.prizeType = '未中奖'
                this.prizeIndex = -1
            }
            this.totalCount = this.newTotalCount
          }
        })
      }, 2000)
    },
    prizeDialog () {
      this.$createDialog({
        showClose: true,
        confirmBtn: {
          active: true,
          disabled: true
        }
      }, (createElement) => {
        return [
          createElement('div', {
            'class': {
              'my-winning-title': true
            },
            slot: 'title'
          }, [createElement('div', {
            'class': {
              'my-content-winning-title': true
            }
          }), createElement('p', '恭喜您！')]),
          createElement('div', {
            'class': {
              'my-content-winning-prize': true
            },
            slot: 'content'
          }, `获得${this.prizeType} [ ${this.prizeName} ] `),
          createElement('div', {
            'class': {
              'my-content-winning-img': true
            },
            slot: 'content'
          }, ''),
          createElement('div', {
            'class': {
              'my-content-winning-button': true
            },
            slot: 'content',
            on: {
              click: this.ticket
            },
            confirm: 'ticket'
          }, '马上去兑奖')
        ]
      }).show()
    },
    ticket () {
      this.$router.push({ name: '我的奖品' })
    },
    getLotteryMore () {
      this.$router.push({ name: '获取更多抽奖' })
    }
  },
  mounted () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    this.initgetMyPointsSummary()
    this.userId = getSessionStorage('userId')
    this.initPrizePages()
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/index.scss";
.lucky-draw-wraper {
  background-color: #3e1c83;
  .header-wraper {
    padding: px2rem(18px) 0 px2rem(20px) px2rem(30px);
    box-sizing: border-box;
    .header {
      height: px2rem(50px);
      line-height: px2rem(50px);
      position: relative;
      .return {
        width: px2rem(40px);
        height: px2rem(28px);
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
      .title {
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: px2rem(50px);
        font-size: px2rem(36px);
      }
    }
  }
  .lucky-draw {
    .banner {
      background: url(~images/dow-ceremony/draw-banner.png) no-repeat;
      height: px2rem(496px);
      // height: 7.13333rem;
      background-size: 100%;
      padding: 0 px2rem(25px);
      position: relative;
      .winner-wraper {
        position: absolute;
        bottom: px2rem(25px);
        width: px2rem(700px);
        height: px2rem(60px);
        line-height: px2rem(60px);
        background: rgba(255, 255, 255, 0.15);
        border-radius: px2rem(30px);
        padding: 0 px2rem(24px);
        box-sizing: border-box;
        font-size: px2rem(28px);
        .winner {
          margin-top: -(px2rem(5px));
        }
      }
    }
    .content-wraper {
      background: url(~images/dow-ceremony/draw-bgc.png) no-repeat;
      height: px2rem(801px);
      background-size: 100%;
      position: relative;
      .drawing {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        li {
          display: flex;
          display: -webkit-flex;
          div {
            flex: 0 0 px2rem(210px);
            width: px2rem(210px);
            height: px2rem(210px);
          }
          span {
            flex: 0 0 px2rem(210px);
            width: px2rem(210px);
            height: px2rem(210px);
            position: absolute;
            top: 0;
          }
          .pic-img1 {
            background: url(~images/dow-ceremony/points10.png) no-repeat;
            background-size: 100%;
            position: absolute;
            top: 0;
          }
          .pic-img2 {
            background: url(~images/dow-ceremony/huawei-pad.png) no-repeat;
            background-size: 100%;
            position: absolute;
            top: 0;
          }
          .pic-img3 {
            background: url(~images/dow-ceremony/points50.png) no-repeat;
            background-size: 100%;
            position: absolute;
            top: 0;
          }
          .pic-img4 {
            background: url(~images/dow-ceremony/tool-car.png) no-repeat;
            background-size: 100%;
            position: absolute;
            top: 0;
          }
          .pic-img5-draw {
            background: url(~images/dow-ceremony/draw.png) no-repeat;
            background-size: 100%;
          }
          .pic-img5-drawing {
            background: url(~images/dow-ceremony/drawing.png) no-repeat;
            background-size: 100%;
            position: absolute;
            top: 0;
          }
          .draw-count {
            display: inline-block;
            padding: px2rem(120px) px2rem(48px) px2rem(48px);
            box-sizing: border-box;
            height: px2rem(33px);
            line-height: px2rem(33px);
            font-size: px2rem(24px);
            letter-spacing: px2rem(2px);
            font-family: PingFangSC-Regular;
            color: rgba(255, 255, 255, 1);
            text-align: center;
          }
          .pic-img6 {
            background: url(~images/dow-ceremony/shopping-card.png) no-repeat;
            background-size: 100%;
            position: absolute;
            top: 0;
          }
          .pic-img7 {
            background: url(~images/dow-ceremony/points100.png) no-repeat;
            background-size: 100%;
            position: absolute;
            top: 0;
          }
          .pic-img8 {
            background: url(~images/dow-ceremony/charge10.png) no-repeat;
            background-size: 100%;
            position: absolute;
            top: 0;
          }
          .pic-img9 {
            background: url(~images/dow-ceremony/points20.png) no-repeat;
            background-size: 100%;
            top: 0;
          }
          .active {
            background: rgba(252, 76, 90, 0.2);
            background: url(~images/dow-ceremony/draw-active.png) no-repeat;
            background-size: 100%;
            position: absolute;
            top: 0;
          }
          div:nth-child(1),
          div:nth-child(2) {
            margin-right: px2rem(15px);
          }
        }
        li:nth-child(1),
        li:nth-child(2) {
          padding-bottom: px2rem(15px);
          box-sizing: border-box;
        }
      }
    }
  }
  .footer-wraper {
    background: url(~images/dow-ceremony/draw-footer.jpg) no-repeat;
    height: px2rem(260px);
    background-size: 100%;
    padding: px2rem(50px) px2rem(150px) px2rem(0px) px2rem(150px);
    box-sizing: border-box;
    .my-points {
      width: px2rem(450px);
      height: px2rem(90px);
      background: linear-gradient(
        132.9deg,
        rgba(255, 152, 91, 1),
        rgba(255, 63, 79, 1)
      );
      box-shadow: px2rem(4px) px2rem(2px) px2rem(4px) px2rem(0px)
        rgba(12, 0, 37, 0.5);
      border-radius: px2rem(45px);
      position: relative;
      .title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        font-size: px2rem(32px);
        font-family: PingFangSC-Semibold;
        color: rgba(255, 255, 255, 1);
      }
    }
    .remark {
      margin: px2rem(40px) px2rem(71px);
      font-size: px2rem(28px);
      font-family: PingFangSC-Regular;
      color: rgba(255, 255, 255, 1);
    }
  }
  .lucky-draw-text {
    font-size: px2rem(28px);
    line-height: px2rem(50px);
    & h3 {
      font-weight: bold;
      margin: 0 0 px2rem(25px) 0;
    }
  }
}
</style>
